<template>
    <b-card title="Add area">
        <b-form-checkbox v-model="othersVisible">Show other areas</b-form-checkbox>
        <hr/>
        <b-form-group>
            <label>Area name:</label>
            <b-form-input v-model="addObject.name"/>
        </b-form-group>

        <ColorPicker v-model="addObject.color"/>

        <div id="mapContainer" style="width: 100%; height: 500px"></div>
        <hr/>
        <b-button variant="primary" @click="addArea">
            <feather-icon
                icon="PlusIcon"
                class="mr-50"
            />
            <span class="align-middle">Add area</span>
        </b-button>
    </b-card>
</template>
<script>

    import mapboxgl from 'mapbox-gl'
    import MapboxDraw from '@mapbox/mapbox-gl-draw'
    import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
    import 'mapbox-gl/dist/mapbox-gl.css'
    import {BCard, BFormInput, BButton, BFormGroup, BFormCheckbox} from 'bootstrap-vue'
    import ColorPicker from '@/views/components/ColorPicker'

    export default {
        components: {
            ColorPicker,
            BCard,
            BFormInput,
            BButton,
            BFormGroup,
            BFormCheckbox
        },
        data() {
            return {
                map: null,
                drawController: null,
                addObject: {
                    name: '',
                    color: '#0080ff',
                    polygon: []
                },
                othersVisible: true,
                areaLayers: []
            }
        },
        methods: {
            loadData() {
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/area')
                loadPromise.then(function(response) {

                    thisIns.removeAreaLayers()
                    response.data.forEach(area => thisIns.addAreaLayer(area))

                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            addArea() {
                if (!this.polygonSelected()) {
                    this.$printError('Polygon not selected')
                    return
                }

                this.addObject.polygon = this.drawController.getAll().features[0].geometry.coordinates[0]

                const thisIns = this
                const loadPromise = this.$http.post('/api/management/v1/area', this.addObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Area added')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.drawController.deleteAll()
                    thisIns.drawController.changeMode('draw_polygon')
                    thisIns.addObject.name = ''
                    thisIns.loadData()
                })
            },
            attachMap() {
                mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

                this.map = new mapboxgl.Map({
                    container: 'mapContainer',
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: this.$store.state.app.default_map_view.center,
                    zoom: this.$store.state.app.default_map_view.zoom
                })

                this.drawController = new MapboxDraw({
                    displayControlsDefault: false,
                    controls: {
                        trash: true
                    },
                    defaultMode: 'draw_polygon'
                })

                this.map.addControl(this.drawController)

                const thisIns = this
                this.map.on('draw.modechange', function() {
                    if (thisIns.drawController.getAll().features.length === 0) {
                        thisIns.drawController.changeMode('draw_polygon')
                    }
                })

                this.map.on('draw.delete', function() {
                    thisIns.drawController.changeMode('draw_polygon')
                })

                return new Promise((resolve) => {
                    this.map.on('load', function() {
                        resolve()
                    })
                })
            },
            addAreaLayer(area) {
                this.map.addSource(area.id, {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': [area.polygon]
                        }
                    }
                })

                this.map.addLayer({
                    'id': `${area.id  }_fill`,
                    'type': 'fill',
                    'source': area.id, // reference the data source
                    'layout': {},
                    'paint': {
                        'fill-color': area.color, // blue color fill
                        'fill-opacity': 0.5
                    }
                }, 'gl-draw-polygon-fill-inactive.cold')

                this.map.addLayer({
                    'id': `${area.id  }_outline`,
                    'type': 'line',
                    'source': area.id,
                    'layout': {},
                    'paint': {
                        'line-color': '#000',
                        'line-width': 1
                    }
                }, 'gl-draw-polygon-fill-inactive.cold')

                this.areaLayers.push(area.id)
            },
            removeAreaLayers() {
                this.areaLayers.forEach(layer => {
                    this.map.removeLayer(`${layer  }_fill`)
                    this.map.removeLayer(`${layer  }_outline`)
                    this.map.removeSource(layer)
                })

                this.areaLayers = []
            },
            polygonSelected() {
                if (!this.drawController) {
                    return false
                } else if (this.drawController.getAll().features.length !== 1) {
                    return false
                } else if (this.drawController.getAll().features.[0].geometry.coordinates.length !== 1) {
                    return false
                } else if (this.drawController.getAll().features.[0].geometry.coordinates[0].length < 3) {
                    return false
                } else {
                    return true
                }
            },
            setOtherAreasVisibility(visible) {
                this.areaLayers.forEach(layer => {
                    this.map.setLayoutProperty(`${layer  }_fill`, 'visibility', (visible) ? 'visible' : 'none')
                    this.map.setLayoutProperty(`${layer  }_outline`, 'visibility', (visible) ? 'visible' : 'none')
                })
            }
        },
        watch: {
            othersVisible(val) {
                this.setOtherAreasVisibility(val)
            }
        },
        mounted() {
            const thisIns = this
            Promise.all([this.attachMap()]).finally(function() {
                thisIns.loadData()
            })
        }
    }
</script>